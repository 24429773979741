<template>
  <div>
    <Menubar :model="menuItems" class="fadeinup animation-duration-300">
      <template #start>
        <img
          alt="logo"
          src="/img/logo.png"
          height="40"
          class="mt-2 mb-1 ml-2 mr-3"
        />
      </template>
      <template #end>
        <Button
          type="button"
          :label="userName"
          icon="pi pi-user"
          class="p-button-text bg-transparent text-white"
          @click="onToggle"
        />
        <Menu ref="menu" :model="menuUser" :popup="true" />
      </template>
    </Menubar>
    <Dialog
      v-model:visible="dialogLogout"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      modal
      closeable
    >
      <template #header>
        <div class="font-bold text-xl">
          <i class="pi pi-sign-out mr-2"></i> Logout
        </div>
      </template>
      Logout now?
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
          @click="dialogLogout = false"
        />
        <Button
          label="Logout"
          icon="pi pi-sign-out"
          autofocus
          @click="$router.push('/logout')"
        />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from 'vue'
import Menubar from 'primevue/menubar'
import { useAuthStore } from '@/store/auth'

const dialogLogout = ref(false)
const auth = useAuthStore()
const menuItems = ref([
  // {
  //   label: 'Home',
  //   icon: 'pi pi-th-large',
  //   to: '/',
  // },
  {
    label: 'Program',
    icon: 'pi pi-folder',
    items: [
      {
        label: 'Timesheet Summary',
        to: '/program/timesheet-summary',
      },
      {
        label: 'Payroll Summary',
        to: '/program/payroll-summary',
      },
      {
        label: 'OT Calculation',
        to: '/program/ot-calculation',
      },
      { separator: true },
      {
        label: 'Employee Management',
        to: '/program/employee-management',
      },
      { separator: true },
      {
        label: 'User Management',
        to: '/program/user-management',
      },
      { separator: true },
      {
        label: 'Application Settings',
        to: '/program/application-settings',
      },
    ],
  },
  {
    label: 'Report',
    icon: 'pi pi-file-o',
    to: '/report/report',
  },
])
const menuUser = ref([
  {
    label: 'Profile',
    icon: 'pi pi-user-edit',
    to: '/profile',
  },
  {
    separator: true,
  },
  {
    label: 'Logout',
    icon: 'pi pi-sign-out',
    command: () => {
      logout()
    },
  },
])
const menu = ref()

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['reads', 'clear'])

const userName = computed(() => {
  return auth.user ? auth.user.name : ''
})

function onToggle(event) {
  menu.value.toggle(event)
}

function logout() {
  dialogLogout.value = true
}
</script>

<style scoped>
.p-menubar {
  background: #2d3748;
  border: none;
  border-radius: 0;
}
</style>
