<template>
  <component :is="$route.meta.layout || 'div'">
    <Toast />
    <nav-bar
      v-if="auth.isAuthenticated"
    />
    <router-view class="m-4" />
    <div class="text-xs ml-4 mb-4">PTRIWeb v{{ appVersion }}</div>
    <Dialog
      header="Update available"
      v-model:visible="refreshDialog"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :modal="true"
      :closable="false"
      position="bottom"
    >
      <p class="m-0">
        An application update is available. Click the Refresh button to update.
      </p>
      <template #footer>
        <Button
          label="Refresh"
          icon="pi pi-check"
          @click="updateApp"
          autofocus
        />
      </template>
    </Dialog>
    <ScrollTop />
  </component>
</template>

<script>
import ScrollTop from 'primevue/scrolltop'
import NavBar from './components/NavBar.vue'
import { useAuthStore } from '@/store/auth'

export default {
  components: { NavBar, ScrollTop },
  inject: ['workbox'],
  created() {
    this.auth = useAuthStore()
    if (this.workbox) {
      this.workbox.addEventListener('waiting', () => {
        this.refreshDialog = true
      })
    }
  },
  data() {
    return {
      refreshDialog: false,
      auth: {},
      appVersion: process.env.VUE_APP_VERSION,
    }
  },
  mounted() {},
  methods: {
    async updateApp() {
      this.refreshDialog = false
      await this.workbox.messageSW({ type: 'SKIP_WAITING' })
    },
  },
}
</script>
