<template>
  <div></div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {},
}
</script>
